/** @format */

.vjs-icons {
  justify-content: center;
  align-items: center;
  flex-direction: row !important; /* Stack items vertically */
  position: absolute;
  color: white;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  width: 10%;
  height: 10%; /* Full height */
  top: 10px;
  left: 10px;
  z-index: 3;
}

.vjs-playerback-button {
  width: 35%;
  padding: 5px;
  margin: 0;
  cursor: pointer;
  background-color: #bdafaf1c;
}

.vjs-playlist-icon-button {
  /* padding: 5px; */
  cursor: pointer;
  border-radius: 5px;
  position: absolute;
  top: 5% !important;
  left: 45% !important;
  z-index: 9999;
  padding: 0;
  margin: 0;
  width: 30%;
  display: flex;
  justify-content: flex-start !important;
  /* background-color: #4f0b0b; */
}

.vjs-playerTitle {
  overflow: hidden !important;
  font-size: 25px !important;
  justify-content: center;
  align-items: center;
  flex-direction: row !important; /* Stack items vertically */
  position: absolute;
  color: white;
  top: 0%;
  z-index: 2;
  padding: 0; /* Ensure no padding in the container itself */
  max-height: 100%;
  max-width: 100%;
  overflow-y: auto; /* Enables vertical scrolling */
  width: 100%;
  height: 10%;
  transition: top 0.4s ease !important; /* Smooth transition */
  display: none; /* Initially hidden */
  justify-content: space-around;
  background: linear-gradient(
    to bottom,
    rgb(10, 9, 9),
    rgba(56, 55, 55, 0) 100%
  );
}

/* Styles for the playlist container */
/* Styles for the playlist container */
.vjs-playlist-container {
  /* font-family: "Franklin Gothic"; */
  /* font-family: "Poppins"; */
  flex-direction: column; /* Stack items vertically */
  position: absolute;
  top: 0px;
  /* border: 1px solid; */
  z-index: 4;
  padding: 0; /* Ensure no padding in the container itself */
  border-radius: 5px;
  max-height: 100%; /* Ensures container doesn't exceed viewport height */
  max-width: 25%; /* Adjust as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  width: 100%;
  height: 100%; /* Full height */
  background-color: rgba(
    7,
    0,
    0,
    0.911
  ) !important; /* Semi-transparent background */
  transition: left 0.4s ease !important; /* Smooth transition */
  display: none; /* Initially hidden */
}

/* Style the scrollbar track */
.vjs-playlist-container::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

/* Style the scrollbar */
.vjs-playlist-container::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Style the scrollbar thumb */
.vjs-playlist-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

/* Style the scrollbar thumb on hover */
.vjs-playlist-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Styles for the playlist header */
.vjs-playlist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: sticky;
  flex-wrap: wrap;
  top: 0;
  width: 100%; /* Full width */
  background: #78757591;
  backdrop-filter: blur(10px);
  color: #fff;
  z-index: 10; /* Ensure header is above other elements */
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
  margin: 0; /* Ensure no margin */
  top: 0; /* Ensure it sticks to the top */
}

/* Styles for the playlist title */
.vjs-playlist-title {
  color: #fff;
  font-size: 18px;
  margin: 0; /* Remove margin */
}

/* Styles for the close button */
.vjs-close-button {
  background-color: transparent;
  color: #fff;
  border: none;
  font-size: 20px;
  cursor: pointer !important;
}

.vjs-search-bar {
  border-color: #ffffff00;
}
.vjs-input-close-button {
  position: absolute;
  right: 3%;
  top: 50%;
  transform: translateY(-44%);
  border: none;
  background: transparent;
  cursor: pointer;
}

.vjs-episode-search-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 7px;
  border-radius: 7px;
  position: sticky;
  top: 0;
  width: 100%;
  color: #fff;
  z-index: 10;
  margin: 0;
  top: 0;
  margin-top: 10px; /* Adjust as needed */
  flex-basis: 100%;
  transition: left 10.4s ease !important;
  animation: fadeIn 0.6s ease-in-out forwards;
}

.vjs-episode-search-box input {
  width: 100%; /* Ensures the input takes the full width of the search box */
  box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
  padding: 5px; /* Adjust as needed */
  font-size: 14px; /* Adjust as needed */
  border-radius: 10px;
  background-color: white;
  height: 10%;
  color: black;
}

.playlist-gap {
  height: 60%;
}

/* Styles for playlist items */
.vjs-playlist-item {
  max-width: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
  padding: 3%;
  width: 100%;
  height: 15%;
  cursor: pointer;
  margin: 0;
  border-bottom: 1px solid #827979;
  box-shadow: 0 4px 8px rgba(104, 22, 3, 0.2);
  transition: background-color 0.3s;
  overflow: hidden;
}

.vjs-playlist-item:hover {
  background: linear-gradient(to bottom, #4f0b0b, rgb(185 0 0) 100%);
}

/* Image container for playlist items */
.vjs-img-container {
  max-height: 100%;
  max-width: 100%;
  height: 90% !important;
  width: 40%;
  margin-right: 3%;
  background-color: #333;
  position: relative; /* Added to position the overlay */
}

.vjs-img-container img {
  width: 100%;
  height: 100%;
  display: block;
}

/* Title overlay for blank images */
.title-overlay {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* Text color */
  background-color: rgb(0 0 0 / 8%);
  padding: 10px;
  border-radius: 5px;
  display: none;
}

.vjs-img-container img[src="./image.jpg"] + .title-overlay {
  display: flex;
  justify-content: center;
}

/* Details container for playlist items */
.vjs-playlist-episode-Details {
  /* background-color: #888; */
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-wrap: wrap;
  align-items: center;
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  overflow: hidden;
}

.vjs-playlist-episode {
  font-size: 1em;
  /* font-weight: bold; */
  /* text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; */
}

.vjs-playlist-episode-duration {
  font-size: 0.9em;
  color: #ada0a0;
  margin-top: 10px;
}

/* Progress bar styles */
.vjs-playlist-progressBar {
  position: relative;
  width: 100%;
  height: 3px;
  background-color: #cfc6c6;
  margin-top: 5px;
  border-radius: 2.5px;
  overflow: hidden;
}

.vjs-playlist-progressBar-inner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  background-color: #d70f0f;
  border-radius: 2.5px;
}
/* Highlight selected playlist item */
.vjs-playlist-item.selected {
  background: linear-gradient(to bottom, rgb(177 27 27), rgb(16 8 8) 100%);
}

/* Responsive adjustments */
@media (max-width: 740px) {
  .vjs-playlist-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .vjs-img-container {
    flex: 1 0 100%;
    max-width: 100%;
    margin-bottom: 2%;
  }

  .vjs-playlist-episode-Details {
    width: 100%;
    text-wrap: nowrap;
    align-items: center;
  }

  .vjs-playlist-episode {
    font-size: 1em;
  }

  .vjs-playlist-episode-duration {
    font-size: 0.8em;
  }
}

/* .skip-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: contain; 
}

.vjs-skip-ad-button {
  display: none; 
}
.vjs-skip-ad-button-container-main {
  background: linear-gradient(to bottom, rgb(71 61 61), rgb(10 7 7 / 73%) 100%);
  color: #fff;
  border: 1px grey !important;
  cursor: pointer;
  border-radius: 10px !important;
  position: absolute;
  top: 75%;
  right: 2%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 10%;
  max-height: 10%;
  height: 7%;
  width: 8%;

}
.vjs-second-skip-ad-buttons {
  background-color: #00000096;
  color: #fff;
  border: 1px grey !important;
  cursor: pointer;
  border-radius: 10px !important;
  position: absolute;
  top: 75%;
  right: 2%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 20%;
  max-height: 20%;
  height: 5%;
  width: 6%;
  padding: 2px;
}
.skip-ad-text {
  margin-right: 5px;
}

.skip-ad-icons {
  display: flex;
  align-items: center;
}

.vjs-second-skip-ad-buttons:hover {
  background: linear-gradient(to bottom, rgb(94, 92, 92), rgb(74 79 93) 100%);
  transform: scale(1.05) !important;
}

.vjs-skip-ad-thumbnails {
  max-width: 70%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vjs-skip-ad-buttons {
  max-width: 30%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.video-js
  .vjs-volume-panel
  .vjs-volume-horizontal
  .vjs-slider
  .vjs-volume-level {
  background-color: #00ff55 !important;
  height: 100%;
  position: absolute;
}

.skip-ad-container {
  max-width: 100%;
  max-height: 100%;
  height: 8%;
  width: 11%;

  color: #fff;
  border: 1px solid !important;
  /* padding: 10px 20px; */
  cursor: pointer;
  border-radius: 3px;
  position: absolute;
  top: 75%; /* 25% above the bottom */
  right: 2%;
  z-index: 10000;
  display: flex;
  align-items: center;

  /* gap: 5px; */
  opacity: 0;
  animation: fadeIn 0.6s ease-in-out forwards;
}
.skip-ad-container-after {
  width: 8%;
  height: 5%;
  display: flex;
}
.skip-ad-container-after:hover {
  background: linear-gradient(to bottom, rgb(25, 24, 24), rgb(30, 30, 31) 100%);
  transform: scale(1.05) !important;
}

.skip-ad-button {
  max-width: 30%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  right: 2%;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  opacity: 0;
  animation: fadeIn 0.6s ease-in-out forwards;
}

.skip-ad-thumbnail {
  max-width: 70%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  /* border-radius: 4px; */
}
.skip-ad-thumbnail-img {
  height: 100%;
  width: 100%;
}

.skip-ad-text {
  margin-right: 5px;
}

.skip-ad-icons {
  display: flex;
  align-items: center;
}

.vjs-posterAd-container {
  background-color: transparent;
  position: absolute;
  bottom: 8%;
  right: 22%;
  height: 13%;
  width: 55%;
  padding: 3px;
  border-radius: 0px;
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* z-index: 9999; */
  z-index: 1;
  transition: left 0.4s ease !important; /* Smooth transition */
}

.vjs-posterAd {
  height: 100%;
  width: 100%;
  border-radius: 0px;
  object-fit: fill;
}

.vjs-posterads-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10000;
  font-size: x-large !important;
}

/* .vjs-upnext-img {
  max-width: 80% !important; 
  max-height: 80% !important;
  width: 100%;
  height: 100%;
  margin-bottom: 10px;
  background-color: #928c9c1a;
} */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 760px) {
  .vjs-icons {
    justify-content: center;
    align-items: center;
    flex-direction: row !important; /* Stack items vertically */
    position: absolute;
    color: white;
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    width: 20%;
    height: 20%; /* Full height */
    top: 10px;
    left: 10px;
    z-index: 3;
  }

  .vjs-playerback-button {
    padding: 5px;
    top: 5% !important;
    left: 5% !important;
    top: 10% !important;
    left: 10% !important;
    cursor: pointer;
  }

  .vjs-overlay-button {
    padding: 10px;
    cursor: pointer;
    /* border-radius: 5px;
    position: absolute;
    top: 10% !important;
    left: 10% !important;
    z-index: 9999;
    display: flex;
    justify-content: flex-start !important; */
  }

  .vjs-playlist-container {
    flex-direction: column; /* Stack items vertically */
    position: absolute;
    top: 0px;
    /* border: 1px solid; */
    z-index: 4;
    padding: 0; /* Ensure no padding in the container itself */
    border-radius: 5px;
    max-height: 100%; /* Ensures container doesn't exceed viewport height */
    max-width: 22%; /* Adjust as needed */
    overflow-y: auto; /* Enables vertical scrolling */
    width: 100%;
    height: 100%; /* Full height */
    background-color: rgba(
      7,
      0,
      0,
      0.911
    ) !important; /* Semi-transparent background */
    transition: left 0.4s ease !important; /* Smooth transition */
    display: none; /* Initially hidden */
  }

  .vjs-playlist-item {
    padding: 3%;
    height: 15%;
    display: -webkit-inline-box;
    width: 100%;
    text-align: left;
    cursor: pointer;
    margin: 0px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 0.1px solid #827979;
    box-shadow: 20px 20px 20px rgba(104, 22, 3, 0.2);
  }

  .vjs-playlist-item:hover {
    background-color: #5a0505e0;
  }

  .vjs-upnext-card:hover {
    transform: scale(1.05) !important;
  }

  .vjs-upnext-title {
    font-size: 16px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .vjs-upnext-img {
    /* width: 100%;
    height: 75%;
    margin-bottom: 10px; */
  }

  .vjs-upnext-button {
    background-color: #ea6262;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
  }

  .vjs-upnext-button:hover {
    background-color: #c94c4c;
  }
  .vjs-upnext-play-icon {
    position: absolute;
    top: 43%; /* Adjust this value to position the icon above the image */
    left: 50%;
    transform: translateX(-50%);
    background-color: #000000b3;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 20px; /* Adjust size as needed */
    line-height: 1; /* Ensure the icon is vertically centered */
  }
}

.vjs-upnext-card {
  max-height: 25%;
  max-width: 20%;
  height: 100%;
  width: 100%;
  border: 1px solid #8930308f;
  position: absolute;
  bottom: 10%;
  right: 5%;
  background-color: rgba(19, 18, 18, 0.8);
  color: #fff;
  padding: 0px;
  border-radius: 5px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9999;
  transition: left 0.4s ease !important;
}
.vjs-upnext-progress-container {
  position: absolute;
  bottom: 0; /* Place at the bottom of the title */
  left: 0;
  width: 100%;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.2); /* A light background */
  border-radius: 3px;
  z-index: 0; /* Make sure it's behind the title */
}

.vjs-upnext-progress-bar {
  height: 100%;
  background-color: #f7eaea;
  width: 5;
  transition: width 0.5s linear;
  border-radius: 3px;
}

.vjs-upnext-card:hover {
  transform: scale(1.05) !important;
}

.vjs-upnext-title {
  max-height: 40%;
  max-width: 100%;
  height: 100%;
  width: 100%;
  font-size: 16px;
  padding: 4%;
  display: flex;
  background-color: #3e3a3a9e;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1; /* Ensure title is above the progress bar */
}

.vjs-upnext-countdown {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.vjs-upnext-countdown-display {
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.vjs-upnext-img {
  background-color: rgba(62, 62, 57, 0);
  max-height: 70%;
  max-width: 100%;
  height: 100%;
  width: 100%;
}

.vjs-upnext-button {
  background-color: #ea6262;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.vjs-upnext-play-icon {
  position: absolute;
  top: 43%; /* Adjust this value to position the icon above the image */
  left: 50%;
  transform: translateX(-50%);
  background-color: #000000b3;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 20px; /* Adjust size as needed */
  line-height: 1; /* Ensure the icon is vertically centered */
}

.video-js .vjs-load-progress,
.vjs-progress-control .vjs-slider:before {
  height: 5px !important;
}
.vjs-marker {
  height: 4px !important;
  background: rgb(244, 220, 4) !important;
  width: 0.5% !important;
  position: absolute !important;
  top: 52% !important;
  padding: 0 !important;
  margin-left: -0.25% !important;
}

/* .vjs-marker
{
  position:absolute;
  background:red;
  width:5px;
  height:110%;
  top:-5%;
  z-index:30;
  margin-left:-3px;
} */
.vjs-playlist-season-title {
  font-family: "Arial", sans-serif; /* Change to your preferred font */
  font-size: 16px; /* Increase font size for prominence */
  font-weight: bold; /* Make the font bold */
  color: #d7c8c8; /* Use a pleasant blue color for the title */
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2),
    rgba(25, 25, 25, 0.2)
  );
  padding: 10px 15px; /* Add padding for spacing */
  margin: 15px 0; /* Add margin for spacing */
  border-radius: 5px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-align: center; /* Align text to the left */
  position: relative; /* For positioning pseudo-elements */
}
.vjs-next-playing-card {
  display: none;
  position: absolute;
  width: 400px; /* Slightly bigger */
  height: auto;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the card */
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  padding: 30px; /* Larger padding */
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vjs-next-playing-title {
  color: #fff;
  font-size: 20px; /* Slightly larger font */
  margin-bottom: 15px;
  text-align: center;
}

.vjs-next-playing-img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 6px;
}

.vjs-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.vjs-start-beginning-btn,
.vjs-play-next-btn {
  flex: 1;
  padding: 10px 20px;
  margin: 5px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.vjs-play-next-btn {
  background-color: #59f321;
}

.vjs-start-beginning-btn:hover,
.vjs-play-next-btn:hover {
  background-color: #333;
}
.vjs-play-next-progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3); /* Progress background color */
  width: 0;
  z-index: 1;
}

.vjs-play-next-btn {
  position: relative;
  overflow: hidden; /* Hide the overflow of progress bar */
}
.vjs-next-playing-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
  display: none;
  z-index: 10;
}
